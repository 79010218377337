<template>
  <v-data-table
    dense
    fixed-header
    v-if="valores !== null"
    multi-sort
    :headers="headers"
    :items="valores"
    locale="es-es"
    :loading="cargando"
    loading-text="Cargando... Por favor espere"
    :items-per-page="10"
    :mobile-breakpoint="870"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      'items-per-page-options': [15, 30, 50, 100],
      'items-per-page-text': 'Filas por página'
    }"
  >
    <template v-slot:item.fecha_creacion="{ item }">
      <v-chip small class="white--text" color="green" label>
        {{ item.fecha_creacion }}
      </v-chip>
    </template>

    <template v-slot:item.fecha_aviso="{ item }">
      <v-chip
        small
        class="white--text"
        color="orange"
        label
        v-show="item.fecha_aviso"
      >
        {{ item.fecha_aviso }}
      </v-chip>
    </template>
    <template v-slot:item.ficha="{ item }">
      <v-chip small class="black--text" color="gray" label>
        {{ item.ficha }}
      </v-chip>
      <!--    <v-tooltip left color="purple">
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            class="pl-2"
            small
            color="green"
            @click="
              $router.push({
                name: 'fdetalle',
                params: { id: item.fichaid, tab: 'avisos' },
              })
            "
            >mdi-open-in-new</v-icon
          >
        </template>

        <span>Ver Ficha</span>
      </v-tooltip> -->
    </template>
    <template v-slot:item.comentarios_html="{ item }">
      <div class="pa-1 font-italic" v-html="item.comentarios_html"></div>
    </template>
    <template v-slot:item.usuario="{ item }">
      <v-chip
        small
        class="white--text"
        color="pink"
        label
        v-show="item.usuario"
      >
        {{ item.usuario }}
      </v-chip>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-btn
        class="white--text float-right"
        color="blue"
        x-small
        @click="
          $router.push({
            name: 'fdetalle',
            params: { id: item.fichaid, tab: 'avisos' },
          })
        "
      >
        ver
        <v-icon right class="pl-2 float-right" small color="white"
          >mdi-open-in-new</v-icon
        >
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Ficha-table",
  props: ["valores", "cargando", "estados"],
  data: () => ({
    headers: [
      {
        text: "Fecha Creación",
        align: "left",
        value: "fecha_creacion",
      },
      {
        text: "Fecha Aviso",
        align: "left",
        value: "fecha_aviso",
      },
      {
        text: "Comentarios",
        align: "left",
        value: "comentarios_html",
      },
      {
        text: "Ficha",
        align: "left",
        value: "ficha",
      },
      {
        text: "Usuario",
        align: "left",
        value: "usuario",
      },
      { text: "Acciones", value: "acciones" },
    ],
  }),
  methods: {
    editar(item) {
      this.$router.push({ name: "fdetalle", params: { id: item.fichaid } });
    },
  },
  mounted() {
    //console.log(this.valores);
  },
};
</script>

<style scoped>
/* .v-data-table th {
  font-size: 20px;
} */

::v-deep.v-data-table td {
  font-size: 0.8em;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.just-line-break {
  white-space: pre-line;
  text-overflow: ellipsis;
}

.line-break-and-tab {
  white-space: pre-wrap;
  text-overflow: ellipsis;
}
</style>
