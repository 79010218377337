<template>
  <v-container class="fill" fluid>
    <!-- FORMULARIO DE FILTRO -->
    <v-card class="mx-auto mb-3" elevation="5" outlined>
      <v-toolbar color="indigo darken-4" dark dense>
        <v-toolbar-title>Avisos</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-list-item three-line>
        <v-list-item-content>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_creacion_ini"
                label="Fecha Creación (desde)"
                dense
                outlined
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_creacion_fin"
                label="Fecha Creación (hasta)"
                dense
                outlined
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="filtro.usuario"
                label="Usuario"
                :items="usuarios"
                item-value="name"
                item-text="name"
                dense
                outlined
                :menu-props="{ closeOnContentClick: true }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.usuario = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.comentarios"
                label="Comentarios"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.ficha"
                label="Ficha"
                dense
                outlined
                class="text-center"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_aviso_ini"
                type="date"
                label="Fecha Aviso (desde)"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_aviso_fin"
                type="date"
                label="Fecha Aviso (hasta)"
                dense
                outlined
                class="text-center"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="filtro.status"
                label="Estado"
                :items="statuses"
                item-value="stat_id"
                item-text="stat_name"
                dense
                outlined
                :menu-props="{ closeOnContentClick: true, maxHeight: 'auto' }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.status = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-tooltip top color="blue">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right ml-1"
                    color="blue"
                    @click="refrescarDatos"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar datos</span>
              </v-tooltip>
              <v-tooltip left color="purple">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right"
                    color="purple"
                    @click="resetFiltros"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar formulario</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="loading"> </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-row>
          <v-col cols="12">
            <avisos-table :valores="valores" :estados="statuses"></avisos-table>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";

import AvisosTable from "@/components/avisos/parts/AvisosTable";

export default {
  name: "Avisos",
  metaInfo: {
    title: 'Avisos'
  },

  components: {
    AvisosTable,
  },

  data: () => ({
    loading: false,
    datos: null,
    statuses: [],
    datosTratados: null,
    usuarios: [],
    filtro: {
      fecha_creacion_ini: null,
      fecha_creacion_fin: null,
      fecha_aviso_ini: null,
      fecha_aviso_fin: null,
      comenarios: null,
      ficha: null,
      usuario: null,
      status: null,
    },
  }),

  methods: {
    ...mapActions("avisos", ["setAvisosAll"]),
    /* funcion para cargar/refrescar datos de la bd */
    refrescarDatos() {
      this.resetFiltros();
      this.loading = true;
      // mostramos el loader
      this.$store.commit("comun/SET_MAIN_LOADER", true);
      this.setAvisosAll().then((res) => {
        //  console.log(res.avisos);
        this.datos = res.avisos;
        this.statuses = res.estados;
        this.usuarios = [...res.users];
        //this.usuarios.unshift({ id: null, username: "TODOS" });
        this.datosTratados = this.generarDatosTratados();
        this.$store.commit("comun/SET_MAIN_LOADER", false);
        this.loading = false;
      });
    },

    /* funcion para resetear los filtros */
    resetFiltros() {
      //this.filtro = resetFiltros
      Object.keys(this.filtro).forEach((e) => (this.filtro[e] = null));
    },

    /* funcion para generar el array de datos para enviarlos a la tabla */
    generarDatosTratados_old() {
      // console.log(this.datos);
      const salida = this.datos.reduce((acc, curr) => {
        let fecha_creacion = moment(curr.reno_creationdate, "YYYY-MM-DD");
        let fecha_aviso = moment(curr.reno_notificationdate, "YYYY-MM-DD");

        fecha_creacion = fecha_creacion.isValid()
          ? fecha_creacion.format("DD/MM/YYYY")
          : null;
        fecha_aviso = fecha_aviso.isValid()
          ? fecha_aviso.format("DD/MM/YYYY")
          : null;
        // console.log(fecha_aviso);

        acc.push({
          id: curr.reno_id,
          fecha_creacion: fecha_creacion,
          fecha_aviso: fecha_aviso,
          comentarios:
            curr.reno_comments !== "undefined" ? curr.reno_comments : "",
          ficha: curr.record.reco_name,
          fichaid: curr.record.reco_id,
          usuario: curr.reno_user,
        });

        return acc;
      }, []);

      //antes de devolver los datos, limpiamos conforme a las especificaciones
      const test = _.groupBy(salida, "fichaid");

      return salida;
    },
    generarDatosTratados() {
      const salida = this.datos.reduce((acc, curr) => {
        let fecha_creacion = moment(curr.reno_creationdate, "YYYY-MM-DD");
        let fecha_aviso = moment(curr.reno_notificationdate, "YYYY-MM-DD");
        fecha_creacion = fecha_creacion.isValid()
          ? fecha_creacion.format("DD/MM/YYYY")
          : null;
        fecha_aviso = fecha_aviso.isValid()
          ? fecha_aviso.format("DD/MM/YYYY")
          : null;

        acc.push({
          id: curr.reno_id,
          fecha_creacion: fecha_creacion,
          fecha_aviso: fecha_aviso,
          comentarios:
            curr.reno_comments !== "undefined" ? curr.reno_comments : "",
          comentarios_html:
            curr.reno_comments !== "undefined"
              ? curr.reno_comments.trim().replaceAll("\n", "<br/>")
              : "",
          ficha: curr.reco_name,
          fichaid: curr.reno_idrecord,
          /*  usuario: curr.reno_user, */
          usuario: curr.user_name,
          status: curr.reco_idstatus,
        });

        return acc;
      }, []);

      //antes de devolver los datos, limpiamos conforme a las especificaciones

      return salida.sort(
        (d1, d2) =>
          new Date(d1.fecha_creacion).getTime() -
          new Date(d2.fecha_creacion).getTime()
      );
    },
  },

  computed: {
    valores() {
      if (this.datos === null && this.datosTratados === null) {
        return null;
      }
      let tmpFiltro = {};
      Object.keys(this.filtro).forEach((e) => {
        if (
          !!this.filtro[e] &&
          (this.filtro[e].length > 0 || Number.isInteger(this.filtro[e]))
        ) {
          const tKey = e;
          tmpFiltro[tKey] = this.filtro[e];
        }
      });
      // console.log(this.datosTratados);
      return filtros(this.datosTratados, tmpFiltro);
    },
  },

  mounted() {
    this.refrescarDatos();
  },
};

/* funcion para filtrar los datos */
const filtros = (data, _filtros) => {
  var filterKeys = Object.keys(_filtros);
  // console.log(filterKeys, filtros);
  const salida = data.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      //comprobamos que el filtro actual no esté vacio
      if (!_filtros[eachKey].length && !Number.isInteger(_filtros[eachKey])) {
        return true;
      }

      if (eachKey === "usuario") {
        return eachObj[eachKey] === _filtros[eachKey];
      }

      if (eachKey === "status") {
        return eachObj[eachKey] === _filtros[eachKey];
      }

      //comprobamos que el filtro actual sea o no de fecha

      if (eachKey.includes("fecha_creacion")) {
        return compararFechas(
          _filtros.fecha_creacion_ini,
          _filtros.fecha_creacion_fin || null,
          eachObj.fecha_creacion
        );
      }

      if (eachKey.includes("fecha_aviso")) {
        return compararFechas(
          _filtros.fecha_aviso_ini,
          _filtros.fecha_aviso_fin,
          eachObj.fecha_aviso
        );
      }

      //comprobamos si es un filtro de especialidades
      if (eachKey === "specialties") {
        if (eachObj[eachKey].indexOf(_filtros[eachKey]) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      //comparamos un texto cualquiera
      if (compararRegexStr(_filtros[eachKey], eachObj[eachKey])) {
        return true;
      }
    });
  });
  return salida;
};

/* funcion para comparar via regex los strings */
const compararRegexStr = (cadena, texto) => {
  //la cadena es lo que escribo en los inputs
  let pattern = texto.toLowerCase();

  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  //escapamos cualquier caracter especial que estemos buscando
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};

/* funcion para comparar fechas */
const compararFechas = (fechaIni, fechaFin, fechaControl) => {
  //si la fecha de inicio no está seteada devolvemos true y no comparamos
  if (!fechaIni) {
    return true;
  }

  //si no hay fecha fin comparamos las fechas mayor e igula que FechaControl
  if (!fechaFin) {
    const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
    const mFechaControl = moment(fechaControl, "DD/MM/YYYY");
    return mFechaControl.isSameOrAfter(mFechaIni);
  }
  //si está seteada tbn la fecha de fin
  const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
  const mFechaControl = moment(fechaControl, "DD/MM/YYYY");
  const mFechaFin = moment(fechaFin).add(1, "d").format("YYYY-MM-DD");

  return (
    mFechaControl.isSameOrAfter(mFechaIni) && mFechaControl.isBefore(mFechaFin)
  );
};
</script>

<style scoped>
.v-input {
  font-size: 0.9em;
}
/deep/.v-label {
  font-size: 1em;
}
</style>
